<template>
    <sections>
        <section-jumbo
            padding="py-72"
            :image="jumbo.imageSizes"
            :imageSet="jumbo.imageSet"
            :imageSizes="jumbo.imageSizes"
        ></section-jumbo>
        <section-text
            heading="Bespoke Software Development"
            content="<article-home />"
            buttonText="Contact Helpdesk"
            buttonRoute="/contact"
        ></section-text>
    </sections>
</template>

<script setup>
const $config = useRuntimeConfig();

// https://www.responsivebreakpoints.com/
// https://cloudinary.com/tools/jpg-to-webp
const jumbo = {
    imageSizes: '(max-width: 1400px) 100vw, 1400px',
    imageSet: `
        ${$config.public.images}home-jumbo-200.webp 200w,
        ${$config.public.images}home-jumbo-685.webp 685w,
        ${$config.public.images}home-jumbo-1033.webp 1033w,
        ${$config.public.images}home-jumbo-1284.webp 1284w,
        ${$config.public.images}home-jumbo-1400.webp 1400w
    `,
    image: `${$config.public.images}home-jumbo.jpg`,
};

useSeo().buildHead({
    title: $config.public.siteName + ' | Software Development',
    description: $config.public.siteStrapline,
    index: true,
});
</script>
