<template>
    <div class="section-jumbo" :class="color">
        <div class="relative isolate overflow-hidden">
            <image-jumbo
                v-if="image"
                :image="image"
                :imageSet="imageSet"
                :imageSizes="imageSizes"
                :imageAlt="imageAlt"
                :imagePosition="imagePosition"
                :imageLoading="imageLoading"
                :imagePriority="imagePriority"
                :imageOpacity="imageOpacity"
            ></image-jumbo>
            <layout-contained :class="innerClasses">
                <content-headline
                    v-if="heading"
                    :heading="heading"
                    :headingColor="headingColor"
                    :headingComponent="headingComponent"
                    :marginBottom="content ? 'mb-6' : 'mb-0'"
                    :textCentered="textCentered"
                ></content-headline>
                <content-text
                    v-if="content"
                    :html="content"
                    :contentColor="contentColor"
                    :textCentered="textCentered"
                ></content-text>
                <div v-if="buttonText" class="mt-8">
                    <button-primary
                        :buttonColor="buttonColor || undefined"
                        :buttonRoute="buttonRoute"
                        :buttonText="buttonText"
                        :buttonClick="buttonClick"
                        :buttonClickParams="buttonClickParams"
                    ></button-primary>
                </div>
                <slot name="custom"></slot>
            </layout-contained>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionJumbo',
    props: {
        padding: {
            required: false,
            default: 'py-32 sm:py-48 lg:py-56',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-900',
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageSet: {
            required: false,
            type: String,
            default: null,
        },
        imageSizes: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imagePosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        imageLoading: {
            required: false,
            type: String,
            default: undefined,
        },
        imagePriority: {
            required: false,
            type: String,
            default: undefined,
        },
        imageOpacity: {
            required: false,
            type: String,
            default: 'opacity-100',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: true,
        },
    },
    computed: {
        innerClasses () {
            const cls = {};
            cls[this.padding] = true;
            cls['text-center'] = !!this.textCentered;
            return cls;
        },
    },
};
</script>
