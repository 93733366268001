<template>
    <img
        class="absolute inset-0 -z-10 h-full w-full object-cover"
        :class="imageClasses"
        :sizes="imageSizes"
        :srcset="imageSet"
        :src="image"
        :alt="imageAlt"
        :loading="imageLoading"
        :fetchpriority="imagePriority"
    />
</template>

<script>
export default {
    name: 'ImageJumbo',
    props: {
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageSet: {
            required: false,
            type: String,
            default: null,
        },
        imageSizes: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imagePosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        imageOpacity: {
            required: false,
            type: String,
            default: 'opacity-100',
        },
        imageLoading: {
            required: false,
            type: String,
            default: 'eager', // eager, lazy
        },
        imagePriority: {
            required: false,
            type: String,
            default: 'high',
        },
    },
    computed: {
        imageClasses () {
            const cls = {};
            cls[this.imagePosition] = true;
            cls[this.imageOpacity] = true;
            return cls;
        },
    },
};
</script>
